import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 30vw;
  cursor: pointer;

  @media screen and (max-width: 414px) {
    height: auto;
    margin-bottom: ${themeSettings.mobileMargins.page};
  }
`

const CardInfo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;

  @media screen and (max-width: 1024px) {
    background-color: transparent;
  }

  & > h3 {
    position: absolute;
    /* line-height: 0.5; */
    display: inline;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &:nth-child(1) {
      top: ${themeSettings.margins.page};
      left: ${themeSettings.margins.page};
    }

    &:nth-child(2) {
      top: ${themeSettings.margins.page};
      right: ${themeSettings.margins.page};
      text-align: right;
    }

    &:nth-child(3) {
      bottom: ${themeSettings.margins.page};
      left: ${themeSettings.margins.page};
    }

    &:nth-child(4) {
      bottom: ${themeSettings.margins.page};
      right: ${themeSettings.margins.page};
      text-align: right;
    }
  }
`

const CardInfoBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0;
`

const MobileInfo = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    box-sizing: border-box;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${themeSettings.margins.page};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 2;
    color: ${props => (props.textColor === "Weiss" ? "#fff" : "#000")};
  }
  @media screen and (max-width: 414px) {
    padding: ${themeSettings.mobileMargins.page};
    h3 {
      font-size: 10px;
    }
  }
`

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
`

const Dot = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  opacity: 0;
  border-radius: 50%;
  z-index: 2;
  animation: 1.4s ${pulse} infinite ease-out;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const Video = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 ${themeSettings.margins.page};
  background-color: ${themeSettings.colors.grey};
  overflow: hidden;
  pointer-events: none;

  @media screen and (max-width: 414px) {
    position: relative;
    top: initial;
    left: initial;
    height: 56.56vw;
  }
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;

  iframe {
    width: 100%;
    height: 56.25%;
    min-height: 110%;
    min-width: 177.77%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 1024px) {
      min-width: 380%;
    }
  }
`

const Background = styled.picture`
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 414px) {
    position: relative;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 414px) {
      height: auto;
      object-fit: contain;
      vertical-align: bottom;
    }
  }
`

export default {
  Card,
  CardInfo,
  CardInfoBackground,
  MobileInfo,
  Dot,
  Video,
  VideoWrapper,
  Background,
}
