import React from "react"
import Styled from "./styled"

const Arrow = props => {
  return (
    <Styled.Arrow
      style={{
        opacity: props.visibility,
        top: props.positionY,
        left: props.positionX,
        transform: `translate(-50%, -50%) rotate(${props.rotation}deg)`
      }}
      mobileVisibility={ props.mobileVisibility }
      onMouseMove={ props.onMouseMoveEvent }
      onMouseOver={ props.onMouseOverEvent }
      onMouseOut={ props.onMouseOutEvent }
      onClick={ props.onClickEvent }
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="28.976" height="20"><path d="M15.63 0l8.5 8.622H0v2.717h24.173L15.63 20h4.17l9.173-10L19.8 0z" fill="#1d1d1b"/></svg>
    </Styled.Arrow>
  )
}

export default Arrow