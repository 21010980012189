import React, { useState, useEffect } from "react"
import SliderHeader from "./header"
import SliderInfo from "./info"
import Slides from "./slides"
import ProjectDescription from "../project-description"
import Styled from "./styled"
import themeSettings from "../../styles/theme"

const Slider = props => {

  const [isProjectDescriptionOpen, setIsProjectDescriptionOpen] = useState(false)
  const [sliderHeight, setSliderHeight] = useState(0)

  const showProjectDescription = () => {
    setIsProjectDescriptionOpen(true)
  }

  const hideProjectDescription = event => {
    event.preventDefault()
    setIsProjectDescriptionOpen(false)
  }

  useEffect(() => {
    if (window.innerWidth <= 415) {
      setSliderHeight(window.innerHeight - themeSettings.mobileNavHeight)
    }
    if (window.innerWidth > 416) {
      setSliderHeight(window.innerHeight - themeSettings.navHeight)
    }
    const resetSliderHeightOnResize = () => {
      if (window.innerWidth > 415) {
        setSliderHeight(window.innerHeight - themeSettings.navHeight)
      } else {
        setSliderHeight(window.innerHeight - themeSettings.mobileNavHeight)
      }
    }

    window.addEventListener("resize", () => {
      resetSliderHeightOnResize()
    })
    return () => {
      window.removeEventListener("resize", () => {
        resetSliderHeightOnResize()
      })
    }
  }, [])

  return (
    <Styled.Slider
      style={{ height: `${sliderHeight}px` }}
    >
      <ProjectDescription
        {...props}
        hideProjectDescription={ hideProjectDescription }
        isActive={ isProjectDescriptionOpen }
      />
      <SliderHeader
        {...props}
        showProjectDescription={ showProjectDescription }
      />
      <SliderInfo
        {...props}
        showProjectDescription={ showProjectDescription }
      />
      <Slides {...props} />
    </Styled.Slider>
  )
}

export default Slider