import styled from "styled-components"
import themeSettings from "../../styles/theme"

const sliderHeaderHeight = "100px"
const sliderInfoHeight = "60px"
const mobileSliderHeaderHeight = "40px"
const mobileSliderInfoHeight = "40px"

const Slider = styled.section`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: calc(100vh - ${themeSettings.navHeight}px);

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    /* display: initial; */
    top: ${themeSettings.navHeight}px;
  }

  @media screen and (max-width: 414px) {
    position: fixed;
    height: calc(100vh - ${themeSettings.mobileNavHeight}px);
    /* overflow: hidden; */
    top: ${themeSettings.mobileNavHeight}px;
  }
`

const Nav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const NavPrev = styled.div`
  width: 50%;
  height: 100%;
`

const NavNext = styled.div`
  width: 50%;
  height: 100%;
`

const Header = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${sliderHeaderHeight};
  padding: 0 ${themeSettings.margins.page};
  z-index: 10;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 414px) {
    height: ${mobileSliderHeaderHeight};
    padding: 0 ${themeSettings.mobileMargins.page};
  }
`

const Info = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${themeSettings.margins.page};
  height: ${sliderInfoHeight};

  @media screen and (max-width: 414px) {
    height: ${mobileSliderInfoHeight};
    padding: 0 ${themeSettings.mobileMargins.page};
    /* bottom: calc((-${mobileSliderInfoHeight} / 2)); */
  }

  & > span {
    cursor: pointer;
  }

  /* Making sure Year is always horizontally centered */
  & > h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Count = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: -${sliderInfoHeight};
  left: 0;
  width: 100%;
  height: ${sliderInfoHeight};
  z-index: 999;

  text-align: right;

  pointer-events: none;

  padding: ${themeSettings.margins.page};

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.mobileMargins.page};
    /* bottom: -${mobileSliderInfoHeight}; */
    height: ${mobileSliderInfoHeight};
  }
`

const DividerContainer = styled.div`
  height: 21px;
  width: 22px;
  margin: 0 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: skew(-20deg) translateY(1px);

  @media screen and (max-width: 1024px) {
    height: 18px;
    transform: skew(-20deg) translateY(1px);
  }

  @media screen and (max-width: 1024px) {
    height: 14px;
    transform: skew(-20deg) translateY(1px);
    margin: 0 2px;
  }
`

const Divider = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${themeSettings.colors.dark};

  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
`

// Holds all slides
const Container = styled.div`
  position: absolute;
  width: 100%;
  top: ${sliderHeaderHeight};
  left: 0;
  height: calc(100% - ${sliderHeaderHeight} - ${sliderInfoHeight});

  display: flex;
  align-items: center;
  justify-content: center;

  /* @media screen and (max-width: 414px) {
    top: ${mobileSliderHeaderHeight};
    height: calc(100% - ${mobileSliderHeaderHeight} - ${mobileSliderInfoHeight});
  } */
`

const DesktopSlides = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const TouchSlider = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    margin-top: 40px;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    height: calc(100% - 80px);
    /* background-color: green; */
    /* class "carousel" */
    & > div {
      height: 100%;
      /* max-height: 100%; */
      /* class "horizontalSlider" */
      & > div {
        height: 100%;
        max-height: 100%;
        /* class "carousel__slider-tray-wrapper" */
        & > div {
          height: 100%;
          max-height: 100%;
          /* class "sliderTray" */
          & > ul {
            height: 100%;
            max-height: 100%;
            display: flex;
            transition: transform 0.3s ease;
            & > li {
              height: 100%;
              max-height: 100%;
              /* class "slideInner" */
              & > div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
`

export default {
  Slider,
  Nav,
  NavPrev,
  NavNext,
  Header,
  Info,
  Count,
  DividerContainer,
  Divider,
  Container,
  DesktopSlides,
  TouchSlider,
  // TouchNav,
  // TouchNavPrev,
  // TouchNavNext
}