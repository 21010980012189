import styled from "styled-components"
import themeSettings from "../../styles/theme"

const CoverContainer = styled.section`
  width: 100vw;
  max-width: 100%;
  height: calc(100vh - ${themeSettings.navHeight}px);
  margin-top: ${themeSettings.navHeight}px;
  background-color: ${themeSettings.colors.dark};
  cursor: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const Arrow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 76px;
  z-index: 9999;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
  pointer-events: none;
`

const Background = styled.picture`
  width: 100%;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default {
  CoverContainer,
  Arrow,
  Background
}