import styled from "styled-components"
import themeSettings from "../../styles/theme"

const DescriptionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${props => props.isActive ? "50vw" : 0};
  background-color: ${themeSettings.colors.dark};
  overflow: hidden;
  z-index: ${themeSettings.zIndexes.fullscreenNavigation};

  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  @media screen and (max-width: 1024px) {
    width: ${props => props.isActive ? "100vw" : 0};
    max-width: ${props => props.isActive ? "100%" : 0};
  }
`

const Content = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100%;
  padding: calc(${themeSettings.margins.page} * 1.9) 20vw ${themeSettings.margins.page} ${themeSettings.margins.page};
  color: ${themeSettings.colors.light};

  @media screen and (max-width: 1024px) {
    width: 90vw;
    padding: calc(${themeSettings.margins.page} * 1.9) 8vw ${themeSettings.margins.page} ${themeSettings.margins.page};
  }

  @media screen and (max-width: 414px) {
    width: 94vw;
    padding: ${themeSettings.mobileMargins.page};
  }

  /* Close Link */
  & > span {
    display: inline-block;
    position: absolute;
    left: ${themeSettings.margins.page};
    bottom: ${themeSettings.margins.page};

    @media screen and (max-width: 414px) {
      left: ${themeSettings.mobileMargins.page};
      bottom: ${themeSettings.mobileMargins.page};
    }

    & > a {
      color: ${themeSettings.colors.light} !important;
    }
  }
`

const Title = styled.h2`
  margin-bottom: ${themeSettings.margins.page};
`

const DescriptionParagraph = styled.div`
  margin-bottom: calc(${themeSettings.margins.page} * 1.2);
`

const Info = styled.div`
  font-size: ${themeSettings.fontSizes.xsmall};
  line-height: 1.4;
`

export default {
  DescriptionContainer,
  Content,
  Title,
  DescriptionParagraph,
  Info
}