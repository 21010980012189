import styled from "styled-components"
import themeSettings from "../../styles/theme"

const Section = styled.section`
  width: 100vw;
  max-width: 100%;
  padding-top: calc(50vh - ${themeSettings.navHeight}px);

  @media screen and (max-width: 1024px) {
    display: none;
  }

  & > h3 {
    grid-column: 1 / span 2;
    width: 100%;
    text-align: center;
    margin-bottom: ${themeSettings.margins.large};
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: ${themeSettings.margins.page};
  }
`

export default { Section }