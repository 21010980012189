import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Styled from "./styled"

const ProjectTeaser = props => {
  const [vimeoAccepted, setVimeoAccepted] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem("fb-cookies-accepted") === "true") {
      setVimeoAccepted(true)
    } else {
      setVimeoAccepted(false)
    }
  }, [])

  const [cardInfo, setCardInfo] = useState(false)
  const [dotVisibility, setDotVisibility] = useState(false)

  const toggleCardInfo = () => {
    setCardInfo(!cardInfo)
    setDotVisibility(!dotVisibility)
  }

  const delayLink = (event, linkTarget) => {
    if (window.innerWidth <= 1025) {
      event.preventDefault()
      setTimeout(() => {
        window.location.href = `/projects/${linkTarget}`
      }, 300)
    } else {
      return
    }
  }

  return (
    <Link
      to={`projects/${props.target}`}
      onClick={event => delayLink(event, props.target)}
    >
      <Styled.Card onMouseOver={toggleCardInfo} onMouseOut={toggleCardInfo}>
        <Styled.MobileInfo
          textColor={
            props.textColorMobile?.length >= 1 ? props.textColorMobile : "Dark"
          }
        >
          <h3>{props.title}</h3>
          <h3>{props.category}</h3>
        </Styled.MobileInfo>
        <Styled.CardInfo style={{ opacity: cardInfo ? 1 : 0 }}>
          <h3>{props.title}</h3>
          <h3>{props.category}</h3>
          <h3>Year:</h3>
          <h3>{props.year}</h3>
          <Styled.Dot
            style={{
              backgroundColor: props.dotColor,
              opacity: dotVisibility ? 1 : 0,
            }}
          />
        </Styled.CardInfo>
        <Styled.CardInfoBackground style={{ opacity: cardInfo ? 1 : 0 }} />
        {props.vimeoVideoId ? (
          <Styled.Video>
            <Styled.VideoWrapper>
              {vimeoAccepted && (
                <iframe
                  title="Vimeo Player"
                  src={`https://player.vimeo.com/video/${props.vimeoVideoId}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0`}
                  width="1920"
                  height="1080"
                  frameBorder="0"
                  allow="autoplay"
                />
              )}
            </Styled.VideoWrapper>
          </Styled.Video>
        ) : (
          <Styled.Background>
            <source media="(min-width: 1201px)" srcSet={props.imageUrlLarge} />
            <source
              media="(min-width: 421px) and (max-width: 1200px)"
              srcSet={props.imageUrlMedium}
            />
            <source
              media="(max-width: 420px)"
              srcSet={props.imageUrlAutoHeight}
            />
            <img src={props.imageUrlLarge} alt={props.imageAlt} />
          </Styled.Background>
        )}
      </Styled.Card>
    </Link>
  )
}

export default ProjectTeaser
