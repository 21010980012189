import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProjectTeaser from "../project-teaser-featured"
import Styled from "./styled"

const RelatedProjects = props => {

  const data = useStaticQuery(graphql`
    query RelatedProjectsQuery {
      prismicProject {
        data {

          related_project_one {
            document {
              uid
              data {
                project_title {
                  text
                }
                year {
                  text
                }
                category {
                  document {
                    data {
                      category_title {
                        text
                      }
                    }
                  }
                }
                teaser_image {
                  alt
                  large {
                    url
                  }
                  medium {
                    url
                  }
                }
                teaser_color
              }
            }
          }

          related_project_two {
            document {
              uid
              data {
                project_title {
                  text
                }
                year {
                  text
                }
                category {
                  document {
                    data {
                      category_title {
                        text
                      }
                    }
                  }
                }
                teaser_image {
                  alt
                  large {
                    url
                  }
                  medium {
                    url
                  }
                }
                teaser_color
              }
            }
          }


        }
      }
    }
  `)

  let dataProjectOne = null
  let dataProjectTwo = null

  if (data.prismicProject.data.related_project_one && data.prismicProject.data.related_project_one != null) {
    dataProjectOne = data.prismicProject.data.related_project_one.document["0"]
  }

  if (data.prismicProject.data.related_project_two && data.prismicProject.data.related_project_two != null) {
    dataProjectTwo = data.prismicProject.data.related_project_two.document["0"]
  }

  return (
    <Styled.Section>
      <h3>More Projects!</h3>
      <div>
        <ProjectTeaser
          target={ dataProjectOne.uid }
          title={ dataProjectOne.data.project_title.text }
          category={ dataProjectOne.data.category.document["0"].data.category_title.text }
          year={ dataProjectOne.data.year.text }
          imageAlt={ dataProjectOne.data.teaser_image.alt }
          imageUrlLarge={ dataProjectOne.data.teaser_image.large.url }
          imageUrlMedium={ dataProjectOne.data.teaser_image.medium.url }
          dotColor = { dataProjectOne.data.teaser_color }
        />
        <ProjectTeaser
          target={ dataProjectTwo.uid }
          title={ dataProjectTwo.data.project_title.text }
          category={ dataProjectTwo.data.category.document["0"].data.category_title.text }
          year={ dataProjectTwo.data.year.text }
          imageAlt={ dataProjectTwo.data.teaser_image.alt }
          imageUrlLarge={ dataProjectTwo.data.teaser_image.large.url }
          imageUrlMedium={ dataProjectTwo.data.teaser_image.medium.url }
          dotColor = { dataProjectTwo.data.teaser_color }
        />
      </div>
    </Styled.Section>
  )
}

export default RelatedProjects