import React from "react"
import AnimatedLink from "../animated-link"
import Styled from "./styled"

const ProjectDescription = props => {

  return (
    <Styled.DescriptionContainer
      // style={{ width: props.projectDescriptionWidth }}
      isActive={ props.isActive }
    >
      <Styled.Content>
        <Styled.Title>{ props.input.project_title.text }</Styled.Title>
        <h2>Project description: </h2>
        <Styled.DescriptionParagraph dangerouslySetInnerHTML={{ __html: props.input.project_description.html }} />
        {
          props.input.info_headline.text && props.input.info_paragraph.html
          ? (
              <Styled.Info>
                <h2>{ props.input.info_headline.text }:</h2>
                <div dangerouslySetInnerHTML={{ __html: props.input.info_paragraph.html }} />
              </Styled.Info>
            )
          : null
        }
        <AnimatedLink
          isInternalLink={false}
          target="#"
          linkText="Close"
          onClickAction={ props.hideProjectDescription }
        />
      </Styled.Content>
    </Styled.DescriptionContainer>
  )
}

export default ProjectDescription