import React from "react"
import Styled from "./styled"

const FullscreenImage = (props) => {
  return (
    <Styled.Container
      style={{ zIndex: props.zIndexValue }}
      sizeSettings={ props.sliceInput.primary.image_settings }
    >
      <picture>
        <img
          alt={props.sliceInput.primary.fullscreen_image.alt}
          src={props.sliceInput.primary.fullscreen_image.url}
        />
      </picture>
    </Styled.Container>
  )
}

export default FullscreenImage