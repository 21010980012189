import React, { useState, useEffect } from "react"
import * as Styled from "./styled"

const VideoSlide = props => {
  const [vimeoAccepted, setVimeoAccepted] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem("fb-cookies-accepted") === "true") {
      setVimeoAccepted(true)
    } else {
      setVimeoAccepted(false)
    }
  }, [])

  return (
    <Styled.VideoSlide style={{ zIndex: props.zIndexValue }}>
      <Styled.VideoWrapper>
        {vimeoAccepted && (
          <iframe
            title="Vimeo Player"
            // ref={ videoPlayerRef }
            // src={ `https://player.vimeo.com/video/${props.sliceInput}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0` }
            src={`https://player.vimeo.com/video/${props.sliceInput}${
              props.playBackSettings === "Play on Load"
                ? "?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0"
                : ""
            }`}
            width="1920"
            height="1080"
            frameBorder="0"
            allow="autoplay"
          />
        )}
      </Styled.VideoWrapper>
    </Styled.VideoSlide>
  )
}

export default VideoSlide
