import React from "react"
import AnimatedLink from "../animated-link"
import Styled from "./styled"

const SliderInfo = props => {
  return (
    <Styled.Info>
      <AnimatedLink
        isInternaLink={ false }
        linkText="Info"
        onClickAction={ props.showProjectDescription }
      />
      <h2>{props.input.year.text}</h2>
    </Styled.Info>
  )
}

export default SliderInfo