import React, { useState, useEffect } from "react"
import Styled from "./styled"
import themeSettings from "../../styles/theme"
import Arrow from "../arrow"

const ProjectCover = props => {

  const [heroPointerEvents, setHeroPointerEvents] = useState("none")
  const [cursorVisibility, setCursorVisibility] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0
  })
  const [coverHeight, setCoverHeight] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setHeroPointerEvents("all")
    }, 1600)
  })

  const toggleCursorVisibility = () => {
    setCursorVisibility(!cursorVisibility)
  }

  const cursorMove = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY
    })
  }

  let scrollDistance = null
  useEffect(() => {
    scrollDistance = window.innerHeight - themeSettings.navHeight
    if (window.innerWidth > 1024) {
      setCoverHeight(window.innerHeight - themeSettings.navHeight)
    }
    if (window.innerWidth < 1024) {
      setCoverHeight((window.innerHeight / 2) - themeSettings.navHeight)
    }
  })

  const scrollDown = () => {
    window.scrollTo({
      top: scrollDistance,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <Styled.CoverContainer
      style={{
        pointerEvents: heroPointerEvents,
        height: coverHeight + "px"
      }}
      onClick={ scrollDown }
      onMouseOver={ toggleCursorVisibility }
      onMouseOut={ toggleCursorVisibility }
      onMouseMove={ cursorMove }
    >
      <Arrow
        visibility={ cursorVisibility ? 1 : 0 }
        positionX={ cursorPosition.x }
        positionY={ cursorPosition.y }
        rotation={ 90 }
        mobileVisibility={ false }
      />
      <Styled.Background>
        <source
          media="(min-width: 1921px)"
          srcSet={ props.input.cover_image.xlarge.url }
        />
        <source
          media="(min-width: 1281px) and (max-width: 1920px)"
          srcSet={ props.input.cover_image.large.url }
        />
        <source
          media="(min-width: 766px) and (max-width: 1280px)"
          srcSet={ props.input.cover_image.medium.url }
        />
        <source
          media="(max-width: 765px)"
          srcSet={ props.input.cover_image.small.url }
        />
        <img
          src={ props.input.cover_image.xlarge.url }
          alt={ props.input.cover_image.alt }
        />
      </Styled.Background>
    </Styled.CoverContainer>
  )
}

export default ProjectCover