import React, { useState, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { graphql } from "gatsby"
import LayoutDefault from "../layouts/default"
import ProjectCover from "../components/project-cover"
import Slider from "../components/slider"
import RelatedProjects from "../components/related-projects"
import Footer from "../components/footer"

const ProjectPage = props => {

  const [isDesktop, setIsDesktop] = useState(true)

  const content = props.data.project.data

  useEffect(() => {
    if (window.innerWidth > 1025) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1025) {
        setIsDesktop(true)
      } else {
        setIsDesktop(false)
      }
    })
    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth > 1025) {
          setIsDesktop(true)
        } else {
          setIsDesktop(false)
        }
      })
    }
  }, [])

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <ProjectCover input={ content } />
        <Slider input={ content } />
        <RelatedProjects />
        { isDesktop ? <Footer /> : null }
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default ProjectPage

export const projectQuery = graphql`
  query Project($uid: String) {
    project: prismicProject(uid: { eq: $uid }) {

      uid

      data {

        project_title {
          text
        }

        year {
          text
        }

        category {
          document {
            data {
              category_title {
                text
              }
            }
          }
        }

        cover_image {
          alt
          xlarge {
            url
          }
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
        }

        project_description {
          html
        }

        info_headline {
          text
        }

        info_paragraph {
          html
        }

        related_project_one {
            document {
              uid
              data {
                project_title {
                  text
                }
                year {
                  text
                }
                category {
                  document {
                    data {
                      category_title {
                        text
                      }
                    }
                  }
                }
                teaser_image {
                  alt
                  large {
                    url
                  }
                  medium {
                    url
                  }
                }
              }
            }
          }

          related_project_two {
            document {
              uid
              data {
                project_title {
                  text
                }
                year {
                  text
                }
                category {
                  document {
                    data {
                      category_title {
                        text
                      }
                    }
                  }
                }
                teaser_image {
                  alt
                  large {
                    url
                  }
                  medium {
                    url
                  }
                }
              }
            }
          }

        body {

          # ----- Slice "Textblock" Start -----
          ... on PrismicProjectBodyTextblock {
            id
            slice_type
            primary {
              text_block_headline {
                text
              }
              text_block_body_text {
                html
              }
            }
          }
          # ----- Slice "Textblock" End -----

          # ----- Slice Fullscreen Image Start -----
          ... on PrismicProjectBodyFullscreenImage {
            id
            slice_type
            primary {
              fullscreen_image {
                alt
                url
              }
              image_settings
            }
          }
          # ----- Slice Fullscreen Image End -----

          # ----- Slice Video Start -----
          ... on PrismicProjectBodyVideo {
            id
            slice_type
            primary {
              vimeo_video_id {
                text
              }
              playback_settings
            }
          }
          # ----- Slice Fullscreen Image End -----

        }

        related_project_one {
          document {
            uid
            data {
              project_title {
                text
              }
              category {
                document {
                  data {
                    category_title {
                      text
                    }
                  }
                }
              }
              teaser_image {
                alt
                large {
                  url
                }
                medium {
                  url
                }
              }
            }
          }
        }

        related_project_two {
          document {
            uid
            data {
              project_title {
                text
              }
              category {
                document {
                  data {
                    category_title {
                      text
                    }
                  }
                }
              }
              teaser_image {
                alt
                large {
                  url
                }
                medium {
                  url
                }
              }
            }
          }
        }

      }

    }
  }  
`