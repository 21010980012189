import React, { useState, useEffect } from "react"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import Styled from "./styled"
import themeSettings from "../../styles/theme"
import Arrow from "../arrow"
import Textblock from "../slices/textblock"
import FullscreenImage from "../slices/fullscreen-image"
import VideoSlide from "../slices/video-slide"

const Slides = props => {
 
  const [scrollDistance, setScrollDistance] = useState(0)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const [cursorVisiblity, setCursorVisibility] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorRotation, setCursorRotation] = useState(0)
  const [dividerRotation, setDividerRotation] = useState(0)
  const [screenWidth, setScreenWidth] = useState(0)

  const toggleCursor = () => {
    setCursorVisibility(!cursorVisiblity)
  }

  const cursorMove = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY
    })
  }

  const rotateCursor = () => {
    setCursorRotation(180)
  }

  const unrotateCursor = () => {
    setCursorRotation(0)
  }

  const allSlides = props.input.body && props.input.body.map((slice, sliceIndex) => {
    switch (slice.slice_type) {
      case "textblock":
        return (
          <Textblock
            key={ sliceIndex }
            zIndexValue={ sliceIndex === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
            sliceInput={ slice }
          />
        )
      case "fullscreen_image":
        return (
          <FullscreenImage
            key={ sliceIndex }
            zIndexValue={ sliceIndex === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
            sliceInput={slice}
          />
        )
      case "video":
        return (
          <VideoSlide
            key={ sliceIndex }
            zIndexValue={ sliceIndex === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
            sliceInput={ slice.primary.vimeo_video_id.text }
            playBackSettings={ slice.primary.playback_settings }
          />
        )
      default:
        return null
    }
  })

  const allMobileSlides = props.input.body && props.input.body.map((slice, sliceIndex) => {
    switch (slice.slice_type) {
      case "textblock":
        return (
          <Slide index={ sliceIndex }>
            <Textblock
              sliceInput={ slice }
            />
          </Slide>
        )
      case "fullscreen_image":
        return (
          <Slide index={ sliceIndex }>
            <FullscreenImage
              // zIndexValue={ sliceIndex === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
              sliceInput={ slice }
            />
          </Slide>
        )
      case "video":
        return (
          <Slide index={ sliceIndex }>
            <VideoSlide
              sliceInput={ slice.primary.vimeo_video_id.text }
              playBackSettings={ slice.primary.playback_settings }
            />
          </Slide>
        )
      default:
        return null
    }
  })

  const rotateDivider = () => {
    setDividerRotation(dividerRotation + parseInt(180))
  }

  const goToNextSlide = () => {
    if (currentSlideIndex === allSlides.length - 1) {
      return setCurrentSlideIndex(0), rotateDivider()
    }
    setCurrentSlideIndex(currentSlideIndex + 1)
    setCurrentZIndex(currentZIndex + 1)
    rotateDivider()
  }

  const goToPrevSlide = () => {
    if (currentSlideIndex === 0) {
      return setCurrentSlideIndex(allSlides.length - 1), rotateDivider()
    }
    setCurrentSlideIndex(currentSlideIndex - 1)
    setCurrentZIndex(currentZIndex + 1)
    rotateDivider()
  }

  const updateIndexOnSwipe = event => {
    const touch = event.touches[0]
    const delay = 260
    if (touch.screenX > screenWidth / 2) {
      if (currentSlideIndex === allSlides.length - 1) {
        return setCurrentSlideIndex(0), rotateDivider()
      }
      setTimeout(() => {
        setCurrentSlideIndex(currentSlideIndex + 1)
      }, delay)
    }
    if (touch.screenX < screenWidth / 2) {
      if (currentSlideIndex === 0) {
        return setCurrentSlideIndex(allSlides.length - 1), rotateDivider()
      }
      setTimeout(() => {
        setCurrentSlideIndex(currentSlideIndex - 1)
      }, delay)
    }
    setTimeout(() => {
      rotateDivider()
    }, delay)
  }

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    if (window.innerWidth > 1025) {
      setScrollDistance(window.innerHeight - themeSettings.navHeight)
    }
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth)
      if (window.innerWidth > 1025) {
        setScrollDistance(window.innerHeight - themeSettings.navHeight)
      }
    })
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth)
        if (window.innerWidth > 1025) {
          setScrollDistance(window.innerHeight - themeSettings.navHeight)
        }
      })
    }
  }, [])

  const scrollDown = () => {
    window.scrollTo({
      top: scrollDistance,
      left: 0,
      behavior: "smooth"
    })
  }

  const slideCurrent = currentSlideIndex < 9 ? `0${currentSlideIndex + 1}` : currentSlideIndex + 1
  const slidesTotal= allSlides.length < 9 ? `0${allSlides.length}` : allSlides.length 

  return (
    <>

    <Styled.Container>
      <Arrow
        visibility={ cursorVisiblity ? 1 : 0 }
        positionY={ cursorPosition.y + "px" }
        positionX={ cursorPosition.x + "px" }
        rotation={ cursorRotation }
        mobileVisibility={ false }
      />
      <Styled.Nav
        onMouseOver={ toggleCursor }
        onMouseOut={ toggleCursor }
        onMouseMove={ cursorMove }
        onClick={ scrollDown }
      >
        <Styled.NavPrev
          onClick={ goToPrevSlide }
          onMouseOver={ rotateCursor }
          onMouseOut={ unrotateCursor }
        />
        <Styled.NavNext
          onClick={ goToNextSlide }
        />
      </Styled.Nav>
      <Styled.DesktopSlides>
        { allSlides }
      </Styled.DesktopSlides>
      <Styled.Count>
        <h2>{ slideCurrent }</h2>
        <Styled.DividerContainer>
          <Styled.Divider style={{ transform: `rotate(${dividerRotation}deg)` }} />
        </Styled.DividerContainer>
        <h2>{ slidesTotal }</h2>
      </Styled.Count>
    </Styled.Container>

    <Styled.TouchSlider>
      <CarouselProvider
        naturalSlideWidth={ 100 }
        naturalSlideHeight={ 100 }
        totalSlides={ allSlides.length }
        infinite={ true }
        dragStep={ 1 }
        onTouchStart={ updateIndexOnSwipe }
      >
        <Slider>
          { allMobileSlides }
        </Slider>
      </CarouselProvider>
    </Styled.TouchSlider>

    </>
  )
}

export default Slides