import React from "react"
import Styled from "./styled"

const Textblock = (props) => {
  return (
    <Styled.Container
      style={{ zIndex: props.zIndexValue }}
    >
      <div>
        <h2>{props.sliceInput.primary.text_block_headline.text}</h2>
        <div dangerouslySetInnerHTML={{ __html: props.sliceInput.primary.text_block_body_text.html }} />
      </div>
    </Styled.Container>
  )
}

export default Textblock