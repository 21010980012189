import styled from "styled-components"
import themeSettings from "../../../styles/theme"

export const VideoSlide = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 ${themeSettings.margins.page};

  background-color: ${themeSettings.colors.grey};
  /* background-color: lightblue; */

  overflow: hidden;

  @media screen and (max-width: 1024px) {
    position: relative;
    top: initial;
    left: initial;
  }

  @media screen and (max-width: 414px) {
    padding: 0 ${themeSettings.mobileMargins.page};
  }
`

export const VideoWrapper = styled.div`
  position: absolute;
  /* top: ${themeSettings.margins.page}; */
  top: 0;
  left: ${themeSettings.margins.page};
  /* padding-bottom: 56.25%; */
  overflow: hidden;
  width: calc(100% - (${themeSettings.margins.page} * 2));
  /* height: calc(100% - (${themeSettings.margins.page} * 2)); */
  height: 100%;

  @media screen and (max-width: 1024px) {
    pointer-events: none;
  }

  iframe {
    width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
