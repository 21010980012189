import React from "react"
import AnimatedLink from "../animated-link"
import Styled from "./styled"

const SliderHeader = props => {
  return (
    <Styled.Header>
      <AnimatedLink
        isInternaLink={ false }
        linkText={ props.input.project_title.text }
        onClickAction={ props.showProjectDescription }
      />
      <h2>{props.input.category.document["0"].data.category_title.text}</h2>
    </Styled.Header>
  )
}

export default SliderHeader