import styled from "styled-components"
import themeSettings from "../../../styles/theme"

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${themeSettings.colors.light};

  overflow: hidden;

  @media screen and (max-width: 1024px) {
    position: initial;
    top: initial;
    left: initial;
  }

  & > div {
    text-align: center;
    width: 60%;

    & > h2 {
      margin-bottom: ${themeSettings.margins.medium};
    }
  }
`

export default {
  Container
}