import styled from "styled-components"
import themeSettings from "../../../styles/theme"

const Container = styled.figure`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;

  padding: 0 ${themeSettings.margins.page};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${themeSettings.colors.light};

  @media screen and (max-width: 1024px) {
    position: initial;
    top: initial;
    left: initial;
    /* background-color: red; */
  }

  @media screen and (max-width: 414px) {
    padding: 0 ${themeSettings.mobileMargins.page};
  }

  & > picture {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      height: auto;
    }

    & > img {
      width: ${props => 
        props.sizeSettings === "An Höhe anpassen" ? "auto"
        : props.sizeSettings === "An Breite anpassen" ? "100%"
        : props.sizeSettings === "Vollflächig" ? "100%"
        : "auto"
      };
      height: ${props => 
        props.sizeSettings === "An Höhe anpassen" ? "100%"
        : props.sizeSettings === "An Breite anpassen" ? "auto"
        : props.sizeSettings === "Vollflächig" ? "100%"
        : "100%"
      };
      object-fit: ${props => 
        props.sizeSettings === "An Höhe anpassen" ? "contain"
        : props.sizeSettings === "An Breite anpassen" ? "cover"
        : props.sizeSettings === "Vollflächig" ? "cover"
        : "contain"
      };
      max-width: 100%;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }
`

export default {
  Container
}